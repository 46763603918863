<template>
  <DefaultLayout>
    <v-container>
      <template v-for="(color, i) in config.theme.light">
        <v-btn :key="i" :color="color" class="ma-2">{{ i }} {{ color }}</v-btn>
      </template>
      <v-switch v-model="useDarkTheme" :label="$t('myProfile.switches.useDarkTheme')"></v-switch>

      <v-list-item>
        <v-list-item-content><v-list-item-title class="font-weight-bold"> Dark Mode</v-list-item-title> </v-list-item-content>
        <v-list-item-action><v-switch v-model="$vuetify.theme.dark" /> </v-list-item-action>
      </v-list-item>
      <v-divider />
    </v-container>
  </DefaultLayout>
</template>

<script>
import { mapGetters } from "vuex";
import { setDark, loadThemeFromConfig } from "@/services/theme";
import DefaultLayout from "@/layouts/default.vue";

export default {
  name: "usermngt",
  data() {
    return {
      useDarkTheme: false,
      themes: [
        {
          name: "Theme 1",
          dark: {
            primary: "#21CFF3",
            accent: "#FF4081",
            secondary: "#21dc79",
            success: "#86af3f",
            info: "#f34fc6",
            warning: "#FB8C00",
            error: "#FF5252",
          },
          light: {
            primary: "#22daff",
            accent: "#ff6b99",
            secondary: "#26ff8c",
            success: "#a5d64c",
            info: "#ff53d0",
            warning: "#ff8e00",
            error: "#ff5252",
          },
        },
        {
          name: "Theme 2",
          dark: {
            primary: "#E65100",
            accent: "#7CB342",
            secondary: "#689F38",
            success: "#4CAF50",
            info: "#6156d8",
            warning: "#1565C0",
            error: "#FF7043",
          },
          light: {
            primary: "#ffa450",
            accent: "#a1e754",
            secondary: "#92de4e",
            success: "#6dff74",
            info: "#7365ff",
            warning: "#2e8ac0",
            error: "#ff5e3c",
          },
        },
      ],
    };
  },
  watch: {
    useDarkTheme: function(val) {
      loadThemeFromConfig(this.$vuetify);
      setDark(val);
      this.$vuetify.theme.primary = "#3f51b5";
    },
  },
  components: {
    DefaultLayout,
  },
  computed: {
    ...mapGetters(["config"]),
  },
  methods: {},
};
</script>
